.modal {
    width: 52em !important;

    .tableWrapper {

        table {

            th,
            td {
                vertical-align: middle;

                &:not(:first-child) {
                    text-align: center;
                }

                &:not(:last-child) {
                    box-shadow: inset -1px 0 1px var(--grey-200);
                }
            }


            tr {
                height: 5em;
                max-height: 5em;
            }

            thead {
                tr:first-child {
                    th {
                        width: 20em;

                        &:not(:first-child) {
                            width: 15em;
                        }
                    }
                }
            }
        }
    }
}