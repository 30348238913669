.vz-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 200;
}

.nb-search-dropdown {
    border-color: var(--lite-grey) !important;
    border: 1px;
    border-style: solid;
    border-right: none;
    border-left: none;
}

.nb-search-input {
    border-radius: 10px 0 0 10px;
}

.nb-search-box {
    width: 400px;
    input {
        caret-color: var(--grey-800);
    }
}

.react-select-theme__value-container {
    height: 40px
}

.nb-link {
    color: var(--darkliver);
}

.vizibly-brand {
    width: 110px;
}

.logout-button {
    min-width: 0;
}

.properties-select {
    width: 210px !important;
}

.nav-bar__form {
    margin-left: .5rem;
}

.nav-bar__settings-nav {
    align-items: center;
    margin-left: 1rem;
}
