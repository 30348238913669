.wrapper {
    margin: 5em auto 0 auto;
    height: calc(100vh - 5em);
    width: 100vw;
    padding: 0 1em 1em 1em;
    overflow: auto;

    .dropdownField {
        width: 200px;
        .dropdownSelect {
            padding: 5px 16px;
        }
        .dropdownLabel {
            color: var(--grey-400);
            display: flex;
            flex-direction: row;
            font-size: 0.8rem;
        }
        .selectedItem {
            display: flex;
            flex-direction: row;
            font-weight: 500;
        }
        svg {
            align-self: center;
            margin: 0;
        }
    }

    .sectionDropdownField {
        width: 400px;
        svg {
            align-self: center;
            margin: 0;
        }
        > div {
            border: none;
        }
    }
}