.propertySelector {
    margin-bottom: 1em;
}

.selectDrivers {
    height: 501px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--grey-200);
    border-top: none;
    font-size: 1.25rem;
    font-weight: bold;
}

.buttons {
    margin-top: 0.5em;
    display: flex;
    justify-content: flex-end;

    .button {
        margin-right: 0.5em;

        &:last-child {
            margin-right: 0;
        }
    }
}