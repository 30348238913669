.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .header {
        height: 4em;
        width: 100%;
        border: 1px solid var(--grey-100);
        border-bottom: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0.5em;
        padding-right: 0.5em;

        h4 {
            margin: 0;
        }

        .customLabelWrapper {
            display: flex;
            align-items: center;
        }

        .headerButtons {
            margin-left: auto;
            margin-right: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 0.5em;

            .settingsGear {
                position: relative;

                &.highlight::before {
                    content: "";
                    height: 10px;
                    width: 10px;
                    position: absolute;
                    background: red;
                    border-radius: 50%;
                    top: 7px;
                    right: 14px;
                }
            }
        }
    }

    .container {
        height: calc(100% - 4em);

        .tableWrapper {

            table {

                border-top-left-radius: 0;
                border-top-right-radius: 0;

                th:first-child,
                td:first-child {
                    position: sticky;
                    left: 0;
                    box-shadow: inset -2px 0 0 var(--grey-200);
                }

                th,
                td {
                    vertical-align: middle;
                    border-left: 1px solid var(--grey-50);
                }

                tr {
                    height: 5em;
                    max-height: 5em;
                }

                thead {
                    th:first-child {
                        width: 20em;
                        background-color: inherit;
                        z-index: 2;
                        box-shadow: inset -2px -2px 0 var(--grey-200);
                    }


                    tr:first-child {
                        box-shadow: inset 0 -2px 0 var(--grey-200);
                        background-color: white;

                        th:not(:first-child) {
                            width: 25em;
                        }
                    }

                    tr:nth-child(2) {
                        background-color: var(--primary-50);
                        box-shadow: inset 0 -2px 0 var(--grey-200);

                        th:first-child {
                            padding: 0;

                            div {
                                display: flex;
                                width: 100%;

                                button {
                                    margin-left: auto;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }

                .propertyCell {
                    padding: 10px 12px 10px 12px;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;

                    span {
                        flex: 1 1 auto;
                    }

                    button {
                        flex: 0 1 2rem;
                        margin-left: auto;
                        margin-right: 0;
                        opacity: 0.5;
                    }

                    button:hover {
                        opacity: inherit;
                    }

                }

                tbody {
                    td:first-child {
                        padding: 0;
                        background-color: white;

                        .propertyCell {
                            background-color: unset;
                        }
                    }
                }
            }

        }
    }
}