$ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);

.curveWrapper {
    position: relative;
    color: var(--grey-800);
}

.curveChartWrapper {
    height: 200px;
}

.curveChart {
    height: 200px;
}

.curveCellWrapper {
    display: flex;
    gap: 1rem;
    padding-left: 2rem;

    .curveCell {
        position: relative;

        &:before {
            content: "%";
            position: absolute;
            bottom: 8px;
            right: 4px;
            font-size: 1rem;
            color: var(--grey-200);
        }
    }
}

.curveTableCell {
    padding: 2px !important;
    box-shadow: none !important;
}

.curveTableHeader {
    text-align: center !important;
}

.curveInput {
    padding: 0.2em !important;
    width: 100%;
    height: 100%;
    text-align: right;
}

.curveTotal {
    font-size: 0.75rem;
}

.curvePopupMenu {
    transition:
        transform .5s $ease-out-expo,
        visibility .5s $ease-out-expo,
        opacity .5s $ease-out-expo;
    position: absolute;
    background: #ffffff;
    border: 1px solid var(--grey-200);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    border-radius: 4px;
    padding: .75rem;
    z-index: 1000;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    pointer-events: none;
    opacity: 0;
    transform: translateY(-36px);

    &.isVisible {
        visibility: visible;
        pointer-events: all;
        opacity: 1;
        transform: translateY(-58px);
    }

    .title {
        display: flex;
        justify-content: space-around;
        font-size: .825rem;
        border-bottom: 1px solid var(--grey-100);
        padding-bottom: .25rem;

        span {
            margin-left: 2rem;
            text-transform: uppercase;
            font-weight: 600;
            color: var(--grey-400);
        }
    }

    .curveDistButton {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;

        .curveIcon {
            width: 64px;
        }
    }
}

.footer {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: .5rem;
    margin-left: 2rem;

    .total {
        font-size: 1.2rem;
        font-weight: 600;
        margin-left: .25rem;

        &.inError {
            color: var(--yellow-500);
        }
    }

    h5 {
        margin: 0;
    }
}
