.wrapper {
    width: 100%;
    padding: 1.5rem;
    height: calc(100vh - 75px);
    margin-top: 75px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .pageHeader {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        h2 {
            margin-bottom: 0;
            font-weight: 600;
            color: var(--grey-600);
            font-size: 1.75rem;
        }

        .snapshotDate {
            margin-right: 1rem;
            color: var(--grey-400);
            font-size: .75rem;
        }
    }

    .loading {
        gap: 1rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: var(--grey-600);
    }

    .tableWrapper {
        overflow: hidden;
        flex-shrink: 1;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        border: 1px solid var(--grey-200);
        border-radius: 4px;
    }

    .tableActions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        .legend {
            display: flex;
            align-items: center;
            margin-left: 2rem;
            font-weight: 500;

            .legendBox {
                width: 20px;
                height: 15px;
                background-color: var(--primary-100);
                margin-right: .25rem;
                border: 1px solid var(--grey-200);
                border-bottom: 1px solid var(--primary-400);

                &.legendHalfShade {
                    background: linear-gradient(to top left, var(--primary-100) 50%, white 50%);
                    border-bottom: 1px solid var(--grey-200);
                }
            }

            .legendText {
                color: var(--grey-500);
                font-size: .75rem;
            }
        }

        .actionsLeft {
            display: flex;
        }

        .actionsRight {
            margin-left: auto;
            display: flex;
            gap: 1rem;
        }
    }

    .tableOuter {
        flex-grow: 1;
        flex-shrink: 1;

        .tableInner {
            height: 100%;
            overflow: hidden;
        }

        .tableHeader {
            padding: 1.5rem 1rem;
            border-bottom: 1px solid var(--grey-200);
            display: flex;
            align-items: center;
            height: 68px;
        }

        .table {
            tr:first-child {
                font-weight: 600;

                td {
                    line-height: 1;
                }
            }

            td {
                text-align: right;
                vertical-align: middle;
                padding: 0 .5rem;
                border-color: var(--grey-100);
                min-width: 90px;

                &.summaryColumnFirst {
                    border-left: 3px solid var(--grey-100);
                }

                &.summaryColumn {
                    background-color: var(--grey-50);
                }

                &.shadedCellFull {
                    border-bottom: 1px solid var(--primary-300) !important;
                    font-weight: 500;
                    color: var(--primary-500) !important;
                    background-color: var(--primary-50);
                }

                &.shadedCellHalf {
                    background: linear-gradient(to top left, var(--primary-50) 50%, white 50%);
                }

                &.expandableCol {
                    cursor: pointer;

                    &:hover {
                        background-color: var(--primary-100);
                    }
                }

                &:first-child {
                    text-align: left;
                    border-left: 0;
                    border-right: 3px solid var(--grey-100);
                }

                &.budgetCell {
                    border-left: 3px solid var(--grey-100);
                }

                &.subtotalRow {
                    font-weight: 600;
                    box-shadow: 0 4px 0 -2px var(--grey-400) inset;
                    text-align: right;
                }

                &.subtotalRowFirstCol {
                    background: var(--grey-50);

                    .buttonCellWrapper {
                        justify-content: flex-end;
                    }
                }

                .propertyNameCell {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }
            }

            td:global(.htDimmed) {
                color: var(--grey-800);
            }
        }
    }
}

.buttonWithSortCellWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
        padding: 0;
        margin: 0;
    }
}

.buttonWithSortCellWrapper > div {
    display: flex;
    flex-direction: column;
}

.buttonCellWrapper {
    display: flex;
    align-items: center;
    button {
        margin-left: auto;
        margin-right: 0;
        opacity: 0.3;

        &:hover {
            transition: opacity .2s ease-in-out;
            opacity: 1;
        }
    }
}