.wrapper {

    .header {
        display: flex;
        align-items: center;

        svg {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        background-color: var(--grey-50);
        max-height: 118px;
        overflow-y: auto;
        list-style: none;
        padding: .5rem;
        border-radius: 4px;
        gap: .35rem;
    }

    .warningWrapper {
        text-align: center;
    }

    .warningText {
        color: var(--negative-color);
        font-weight: 600;
        display: inline-block;
        border-radius: 50px;
        background-color: rgba(red, .05);
        padding: .75rem 1.5rem;
        margin-top: 1rem;
        margin-bottom: 0;
    }
}