.modal {
    width: 52em !important;

    .tableWrapper {

        table {

            th,
            td {
                vertical-align: middle;

                &:not(:last-child) {
                    box-shadow: inset -1px 0 1px var(--grey-200);
                }
            }

            th:not(:first-child) {
                text-align: center;
            }

            tr {
                height: 3em;
                max-height: 3em;

                &:has(.compItemRow) {
                    background-color: var(--grey-100);
                    td {
                        box-shadow: unset;
                    }
                }
            }

            thead {
                tr:first-child {
                    th {
                        width: 20em;

                        &:not(:first-child) {
                            width: 15em;
                        }
                    }
                }
            }
        }
    }
}