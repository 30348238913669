.table {
    color: var(--grey-800);

    td, th {
        font-size: .8125rem;
        vertical-align: middle;
        min-width: 50px;
    }

    th {
        font-weight: 600;
        background-color: var(--grey-50);
    }

    td {
        text-align: right;
    }

    :global(.handsontableInputHolder) {
        &:after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: var(--grey-400);
        }
    }

    :global(.handsontableInput) {
        text-align: right;
        align-content: center;
        color: var(--grey-800);
    }

    &.percentageCell {
        td {
            padding-right: 16px;
            position: relative;

            &:after {
                content: "%";
                position: absolute;
                right: 2px;
                top: 50%;
                transform: translateY(-50%);
                color: var(--grey-400);
            }
        }

        :global(.handsontableInputHolder) {
            &:after {
                content: "%";
                right: 3px;
            }
        }

        :global(.handsontableInput) {
            padding-right: 17px;
        }
    }

    &.currencyCell {
        td {
            position: relative;
        }
    }
}
