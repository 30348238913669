$ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);

.wrapper {
    position: relative;
    padding-bottom: 100px;
}

.pageHeader {
    display: flex;
    justify-content: space-between;
}

.section {
    padding: 1rem 0;
    margin-bottom: 1rem;
}

.buttons {
    display: flex;
    justify-content: flex-end;
}

.opMetricErrorMessage {
    font-size: 0.75rem;
    color: var(--negative-color);
}

.opMetricButtons {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.25em;
}

.opMetricTable {
    table-layout: auto !important;

    th {
        padding: 0;
        text-align: center;

        &:first-child {
            text-align: left;
        }
    }

    td {
        vertical-align: middle;
        text-align: center;
        white-space: nowrap;
        padding: 0;

        &:first-child {
            text-align: left;
        }
    }
}

.sectionHeader {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    gap: .5rem;
    cursor: pointer;

    .sectionTitle {
        margin-bottom: 0;
        font-size: 1rem;
    }

    .toggle {
        input {
            margin-top: 4px;
        }

        .sectionTitle {
            width: 100%;
            font-size: 1.25rem;
            font-weight: 500;

            &:before {
                top: 5px;
            }

            svg {
                top: 7px;
            }
        }
    }
}

.tableOuter {
    .table {
        z-index: 1;

        :global(.ht_clone_top) th, :global(.ht_clone_top_left_corner) th, :global(.ht_master) thead th, :global(.ht_clone_left) tbody td {
            background-color: #fff;
            font-weight: 600;
            vertical-align: middle;
        }

        :global(.ht_clone_left) th {
            background-color: #fff;
            font-weight: 600;
            vertical-align: middle;
        }

        :global(.ht_master) td {
            vertical-align: middle;
        }
    }
}

.renewalIncreaseMenu {
    display: flex;
    align-items: center;
    gap: .5rem;

    h5 {
        flex-shrink: 0;
        margin: 0;
    }

    .dropdown {
        width: 220px;
    }

    .pctInput {
        width: 100px;
    }
}

.jobLogModal {
    width: 90vw !important;

    .jobLogModalBody {
        padding: 0;
        padding-bottom: 1.5rem;
    }
}

.reviewBar {
    display: flex;
    align-items: center;
    justify-content: flex-end;;
    position: fixed;
    background-color: rgba(#ffffff, .95);
    border: 1px solid var(--grey-100);
    padding: 1rem;
    width: 100%;
    max-width: 1366px;
    bottom: 16px;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .2);
    z-index: 10;
    border-radius: 4px;
    background-size: 100% 100%;
    color: var(--grey-800);
    gap: 1rem;

    h4 {
        font-size: 1rem;
        margin-bottom: 0;
    }
}
