.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .tableWrapper {
        flex: 0 1 auto;
        overflow: auto;

        border: 1px solid var(--grey-200);
    }

    .pagination {
        margin-top: 0.5em;
        flex: 0 1 1em;
    }
}