$ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);

.wrapper {
    background-color: var(--grey-50);
    padding: .5rem;
    margin: .5rem 0;
    border-radius: 4px;

    &:hover {
        background-color: var(--primary-50);
    }

    .content {
        transition: grid-template-rows 0.5s $ease-out-expo;
        display: grid;
        grid-template-rows: 0fr;

        .contentInner {
            overflow: hidden;

            .innerPaddingSpacer {
                padding-top: .25rem;
            }
        }
    }

    .header {
        display: flex;
        align-items: center;
        gap: .5rem;

        .checkbox {
            .checkboxLabel {
                transition: color .5s $ease-out-expo;
                width: 100%;
                font-weight: 600;
                color: var(--grey-600);
            }

            &:hover {
                .checkboxLabel {
                    color: var(--primary-500);
                }
            }
        }

        .errorIcon {
            transition: opacity .25s ease-in-out;
            opacity: 0;
            fill: var(--yellow-500);

            &.isVisible {
                opacity: 1;
            }
        }
    }

    &.isExpanded {
        background-color: var(--primary-50);

        .content {
            grid-template-rows: 1fr;

            .contentInner {
                animation: setOverflowVisible 0.001s forwards 0.5s;
            }
        }

        .checkbox {
            .checkboxLabel {
                color: var(--primary-500);
            }
        }
    }
}

@keyframes setOverflowVisible {
    to {
        overflow: visible;
    }
}
