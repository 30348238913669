.modal {
    width: 60em;

    .bodyContent {
        display: flex;
        flex-direction: column;

        .description {
            margin-bottom: 2em;
        }

        .driverLines {
            .driverLine {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: .5rem;
                padding-bottom: .5rem;
                height: 3em;

                span {
                    width: 50%;
                    display: inline-block;
                }

                &:first-child {
                    font-weight: 600;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid var(--grey-100);
                }
            }
        }
    }
}